export const styles = (theme) => ({
  dialogRoot: {
    overflow: 'auto',
  },
  headerSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 24px',
    height: 'fit-content',
    backgroundColor: 'unset',
  },
  subtitle: {
    color: theme.palette.text.secondary,
    flexShrink: 2,
  },
  searchBar: {
    height: 40,
    paddingLeft: 4,
    borderRadius: 12,
    backgroundColor: theme.palette.white,
    border: `1px solid ${theme.palette.cloudyGrey}`,
    margin: 'auto 0 0 4px',
    '&.open': {
      width: '220px !important',
    },
    '&:hover': {
      borderColor: theme.palette.black,
    },
    '&:focus-within': {
      borderColor: theme.palette.primary.main,
    },
  },
  searchInput: {
    fontSize: '14px',
    lineHeight: 1.43,
    fontWeight: 'normal',
    color: '#000000',
    '&:-webkit-autofill': { // disable autofill color because it makes it ugly
      WebkitBackgroundClip: 'text',
    },
  },
  greyGrid: {
    padding: '0px 14px',
    position: 'relative',
  },
  buttonBottoms: {
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 68,
    paddingBottom: 14,
    paddingLeft: 24,
    paddingRight: 24,
    backgroundColor: 'unset',
  },
  manualLine: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: '#666666',
  },
  manualButton: {
    color: theme.palette.secondary.main,
    marginLeft: 6,
  },
  disabledSave: {
    color: `${theme.palette.white} !important`,
    opacity: 0.5,
  },
  processing: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.applyOpacityToHex(theme.palette.white, 0.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
