

export const resourceRegisterStyles = ({ palette, spacing, components }) => ({
  rootContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingBottom: 20,
  },
  rowsContainer: {},
  headerContainer: {
    background: components.register.headerBackground,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    minHeight: spacing(5),
    borderBottom: `1px solid ${palette.divider}`,
    padding: `0 ${spacing()} 0 ${spacing(3.5)}`,
  },
  baseRowHead: {
    display: 'flex',
    marginRight: spacing(2),
    color: palette.text.primary,
  },
  firstRowHead: {
    marginLeft: spacing(2),
  },
  smartSearchNoBorder: {
    '&.open': {
      width: '94%',
      borderRadius: 4,
      border: 'none',
      borderColor: palette.cloudyGrey,
      background: palette.white,
      margin: '5px 0',
    },
  },
  smartSearch: {
    '&.open': {
      width: '94%',
      borderRadius: 4,
      border: '1px solid',
      borderColor: palette.cloudyGrey,
      background: palette.white,
      margin: '5px 0',
    },
  },
});

export const treeNodeRowStyles = (theme) => ({
  listContainer: {
    padding: 0,
  },
  collapseContainer: {
    backgroundColor: theme.components.register.headerBackground,
    '&.narrowMode': {
      marginLeft: 11,
    },
  },
});

export const ResourceRowStyles = ({ applyOpacityToHex, spacing, transitions, typography, palette, components }, _props, classes) => ({
  listContainer: {
    padding: 0,
  },
  itemContainer: {
    padding: 0,
    position: 'relative',
    minHeight: spacing(6.5),
    transition: transitions.create(['background', 'border-left-color', 'box-shadow'], { duration: transitions.shorter }),
    '&:hover': {
      background: components.txns.itemContainer,
      [`& .${classes.hoverIcon}`]: {
        opacity: 1,
      },
    },
  },
  itemContainerIsEditing: {
    backgroundColor: palette.silverGrey,
  },
  itemContentContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    '&.narrowMode': {
      padding: 0,
    },

  },
  listItemText: {
    display: 'flex',
    padding: 0,
    marginRight: spacing(2),
    cursor: 'pointer',
    minWidth: spacing(5),
    minHeight: spacing(2.25),
    fontSize: typography.fontSize,
  },
  labelHover: {
    borderRadius: 4,
    '&:hover': {
      backgroundColor: components.categoryRegister.labelHover,
      [`& .${classes.firstItemNoChildren}`]: {
        borderLeftColor: 'transparent',
      },
    },
  },
  noCursorPointer: {
    cursor: 'default',
  },
  textField: {
    borderRadius: 4,
    boxShadow: `0 0 4px 0 ${applyOpacityToHex(palette.secondary.main, 0.6)}`,
    border: `solid 1px ${palette.secondary.main}`,
    backgroundColor: palette.white,
    marginRight: spacing(1),
    padding: `0 ${spacing(1 / 2)}`,
    height: spacing(4),
    width: '85%',
    fontSize: typography.subtitle2.fontSize,
    maxWidth: spacing(17),
  },
  selectField: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  firstItem: {
    marginLeft: spacing(2),
  },
  firstItemNoChildren: {
    paddingLeft: spacing(3 / 4),
    borderLeft: `2px solid ${palette.silverGrey}`,
    marginLeft: spacing(3),
  },
  ...((() => {
    const obj = {};
    const maxLevel = 15;
    Array.from(Array(maxLevel),
      (x, i) => {
        const level = i + 2;
        const base = 8 * 3; // 1 spacing = 8px, L2 starts from
        const steps = 4;

        const increment = level * steps;
        obj[`itemIdent-L${level}`] = {
          paddingLeft: spacing(3 / 4),
          borderLeft: `2px solid ${palette.silverGrey}`,
          marginLeft: level === 2 ? `${base}px` : `${base + increment}px`,
        };
        return null;
      });
    return obj;
  })()),
  collapseIcon: {
    width: 24,
    borderRadius: '50%',
    filter: components.categoryRegister.collapseFilter,
  },
  hoverIcon: {
    opacity: 0,
    transition: transitions.create(['opacity'], { duration: transitions.shortest }),
  },
  deleteIcon: {
    color: components.categoryRegister.icon,
  },
  cancelIcon: {
    color: components.categoryRegister.icon,
  },
  saveIcon: {
    color: palette.secondary.main,
  },
  actionsIconsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    minWidth: spacing(17.5),
  },
  actionsSelectContainer: {
    display: 'flex',
  },
  editButtonsContainer: {
    display: 'flex',
  },
  checkboxScale: {
    transform: 'scale(1.1)',
    '&.Mui-checked': {
      color: 'inherit',
    },
  },
});

export const DeleteDialogStyles = ({ typography, spacing }) => ({
  bold: {
    fontWeight: typography.fontWeightMedium,
  },
  label: {
    color: 'inherit',
  },
  formLabel: {
    alignItems: 'flex-start',
    marginLeft: 0,
    width: '100%',
  },
  select: {
    height: spacing(3.75),
    '& div': {
      height: '100% !important',
      width: '100%',
    },
  },
  selectText: {
    paddingLeft: spacing(1),
  },
});
