import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';
import { useEffectOnce } from 'react-use';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ButtonBase from '@mui/material/ButtonBase';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import Delete from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';

import { chartOfAccountsTypes } from 'companion-app-components/flux/chart-of-accounts';

import CategoryField from 'components/QuickenControls/CategoryField';
import Dump from 'components/Dump';
import { isBrowser } from 'utils/utils';

import arrowIconRight from 'assets/icon-arrow-right.svg';
import arrowIconDown from 'assets/icons-arrow-down.svg';

import { ResourceRowStyles as styles } from '../styles';
import type { CategoryItem, DataDictionaryItems } from '../types';

interface EditingProps {
  requestEditing: (id: string) => void;
  cleanEditingData: (id?: string | null) => void;
  editingId: string | null;
  cancelEditingId: string | null;
}

interface ResourceRowProps {
  item: CategoryItem;
  isChild?: boolean;
  isExpanded?: boolean;
  isParent?: boolean;
  dataDictionary: DataDictionaryItems;
  rowIndex: number[];
  narrowMode?: boolean;
  onCollapse: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onSave: (item: CategoryItem) => void;
  onDelete: (item: CategoryItem) => void;
  onSelect?: (item: CategoryItem) => void;
  selectedItems?: string[];
  onRemoveNewItem: () => void;
  getCategoryByID?: (id: string) => { name: string } | null;
  setDialog: (
    title: string,
    content: React.ReactNode,
    onClose: (dialog: { action: string }) => void,
    actions: string[]
  ) => void;
  editingProps: EditingProps;
  disabled?: boolean;
}

const useStyles = makeStyles()(styles as Record<string, any>);

const ResourceRow: React.FC<ResourceRowProps> = ({
  item: initialItem,
  isChild = false,
  dataDictionary,
  rowIndex,
  narrowMode = false,
  isExpanded,
  isParent,
  onCollapse,
  onSave,
  onDelete,
  onSelect,
  selectedItems,
  onRemoveNewItem,
  getCategoryByID,
  setDialog,
  editingProps,
  disabled = false,
}) => {
  const { classes } = useStyles();
  
  const [item, setItem] = useState<CategoryItem>({ ...initialItem });
  const [isEditing, setIsEditing] = useState(false);
  const [editingProp, setEditingProp] = useState<string | null>(null);
  const [selectOpen, setSelectOpen] = useState(false);

  const hasChanges = () => JSON.stringify(initialItem) !== JSON.stringify(item);

  const { isNewCategory = false } = item;
  const showEditing = isEditing || hasChanges() || isNewCategory;
  const itemClasses = classNames(classes.itemContainer, { [classes.itemContainerIsEditing]: showEditing });
  const indexGenerated = rowIndex.length < 2 ? [...rowIndex, 0] : rowIndex;
  const baseId = `category-${indexGenerated.join('-')}`;

  const requestEditing = () => {
    editingProps.requestEditing(item.id);
  };

  const cleanEditingData = (flagSendId = false) => {
    editingProps.cleanEditingData(flagSendId ? item.id : null);
  };

  const changeEditingProp = (editingData) => {
    const isEditingGenerated = Boolean(editingData);
    if (!isEditingGenerated && !hasChanges()) {
      cleanEditingData();
    }
    setIsEditing(isEditingGenerated);
    setEditingProp(editingData);
  };

  const onEditMode = (editingData) => {
    requestEditing();
    changeEditingProp(editingData);
  };

  const triggerInitialEditing = () => {
    if (isNewCategory && !hasChanges() && editingProp === null) {
      onEditMode('name');
    }
  };

  useEffectOnce(() => triggerInitialEditing());

  useEffect(() => {
    if (initialItem) {
      setItem({ ...initialItem });
    }
  }, [initialItem]);

  const onCollapseFunc = (e) => {
    e.stopPropagation();
    onCollapse(e);
  };

  const renderCollapseIcon = () => 
    isParent ? (
      <ButtonBase
        id={`${baseId}-collapse-button`}
        className={classNames(classes.collapseIcon, narrowMode ? 'narrowMode' : '')}
        onClick={onCollapseFunc}
      >
        <img
          alt={isExpanded ? 'Expand List' : 'Collapse List'}
          src={isExpanded ? arrowIconDown : arrowIconRight}
        />
      </ButtonBase>
    ) : <div className={classNames(classes.collapseIcon, narrowMode ? 'narrowMode' : '')} />;

  const renderTextLabel = ({ index, primary, row }) => {
    const listClasses = index === 0 ? { primary } : {};
    let canGotoEditMode = true;
    let listClassname = classNames(classes.listItemText, row.editable ? classes.labelHover : '');
    let text = item[row.id] ? item[row.id] : row.defaultValue || row.placeholder;

    if (row.type === 'select') {
      text = (row.choices.find((x) => x.value === item[row.id]) || {}).title;
      canGotoEditMode = !isChild;
      listClassname = classNames(listClassname, { [classes.noCursorPointer]: !canGotoEditMode });
    }

    return (<ListItemText
      id={`${baseId}-${row.id}-text`}
      key={row.id}
      primary={text}
      style={{ flex: row.flex }}
      className={listClassname}
      classes={listClasses}
      onClick={row.editable ? (() => canGotoEditMode && onEditMode(row.id)) : undefined}
    />);
  };

  const onDeleteFunc = () => {
    onDelete(item);
  };

  const resetChanges = () => {
    setItem({ ...initialItem });
    setIsEditing(false);
    setEditingProp(null);
    setSelectOpen(false);
    if (isNewCategory) {
      onRemoveNewItem();
    }
    cleanEditingData();
  };

  const onCancel = (flagShowName = false) => {
    const { name } = item;

    if (hasChanges()) {
      setDialog(
        'Discard Changes',
        <>Are you sure you want to cancel changes to {flagShowName ? <strong>{name}</strong> : 'this'} category?</>,
        (dialog) => {
          if (['YES', 'Enter'].includes(dialog.action)) {
            resetChanges();
          }
          if (['CANCEL'].includes(dialog.action)) {
            cleanEditingData(true);
          }
        },
        ['CANCEL', 'YES'],
      );
      return;
    }
    resetChanges();
  };

  const onSaveFunc = () => {
    if (hasChanges()) {
      onSave(item);
      cleanEditingData();
    } else {
      onCancel();
    }
  };

  const renderActionIcons = ({ row }) => {
    if (row.type === 'actionsSelect') {
      // Use item.name for Payees and item.id for Categories, Tags, or Accounts based on `count`
      const selected = item.count 
        ? selectedItems?.includes(item.name) 
        : selectedItems?.includes(item.id);
      return (
        <div key={row.id} className={classes.actionsSelectContainer} style={{ flex: row.flex }}>
          <Checkbox
            disabled={disabled}
            onClick={onSelect && !disabled ? () => onSelect(item) : undefined}
            checked={selected}
            id={`${baseId}-select-box`}
            className={classes.actionIcon}
            aria-label="Select"
            classes={{ root: classes.checkboxScale }}
          />
        </div>
      );
    }
    return (
      <div key={row.id} className={classes.actionsIconsContainer} style={{ flex: row.flex }}>
        {!isNewCategory &&
          <IconButton
            id={`${baseId}-delete-button`}
            className={classNames(classes.hoverIcon, classes.actionIcon)}
            aria-label="Delete"
            onClick={onDeleteFunc}
            size="large"
          >
            <Delete className={classes.deleteIcon} />
          </IconButton>}
        {showEditing && (
          <div className={classes.editButtonsContainer}>
            <IconButton
              id={`${baseId}-cancel-button`}
              className={classes.actionIcon}
              aria-label="Cancel"
              onClick={() => onCancel()}
              size="large"
            >
              <Cancel className={classes.cancelIcon} />
            </IconButton>
            <IconButton
              id={`${baseId}-save-button`}
              className={classes.actionIcon}
              aria-label="Save"
              onClick={onSaveFunc}
              size="large"
            >
              <CheckCircle className={classes.saveIcon} />
            </IconButton>
          </div>
        )}
      </div>
    );
  };

  const onBlur = (prop: string) => (e: React.FocusEvent) => {
    if (
      prop !== 'parent' || 
      (prop === 'parent' && !(e && e.relatedTarget) && !isBrowser('safari') && !isBrowser('firefox'))
    ) {
      setEditingProp(null);
      setIsEditing(false);
    }
  };

  const onChange = (editProp) => (e) => {
    const getCategory = () => {
      if (getCategoryByID && getCategoryByID(e.id)) return getCategoryByID(e.id)?.name;
      return '';
    };
    const newItem = editProp === 'parent' ? {
      ...item,
      parent: (e && e.id && e.id !== '0') ? (getCategory()) : '',
      values: {
        ...item.values,
        parent: e,
      },
    } : {
      ...item,
      [editProp]: e.target.value,
    };
    setItem({ ...newItem });
  };

  const onKeyPress = (nextRow) => (event) => {
    // If select is open all keyPress events must be handled by the selectComponent
    if (selectOpen) { return; }
    if (event.key === 'Enter') {
      onSaveFunc();
    } else if (event.key === 'Escape') {
      onCancel();
    } else if (event.key === 'Tab' && nextRow && nextRow.id && nextRow.editable) {
      setTimeout(() => onEditMode(nextRow.id), 0);
    }
  };

  const onSelectToggle = () => {
    setSelectOpen(!selectOpen);
  };

  const renderSelectItems = (choices) => choices.map((option) => (
    <MenuItem key={option.value} value={option.value}>
      {option.title}
    </MenuItem>
  ));

  const renderFields = ({ row, index, root, nextRow }) => {
    const sharedProps = {
      id: `${baseId}-${row.id}-field`,
      key: row.id,
      autoFocus: true,
      onBlur: onBlur(row.id),
      onChange: onChange(row.id),
      onKeyPress: onKeyPress(nextRow),
      onKeyDown: onKeyPress(nextRow),
      placeholder: row.placeholder,
      value: item[row.id] ? item[row.id] : '',
    };
    let fieldProps = {};

    switch (row.type) {
      case 'string': {
        const inputDefaultClasses = { input: classes.textField };
        fieldProps = {
          InputProps: {
            disableUnderline: true,
            classes: index === 0 ? { root, ...inputDefaultClasses } : inputDefaultClasses,
          },
        };
        break;
      }
      case 'select': {
        fieldProps = {
          select: true,
          classes: {
            root: classes.textField,
          },
          variant: 'standard',
          SelectProps: {
            disableUnderline: true,
            open: selectOpen,
            onOpen: onSelectToggle,
            onClose: onSelectToggle,
            classes: { select: classes.selectField },
          },
          children: renderSelectItems(row.choices),
        };
        break;
      }
      case 'CategoryField': {
        fieldProps = {
          value: '',
          InputProps: {
            classes: { root: classes.textField },
            disableUnderline: true,
            'aria-label': 'Subcategory',
            inputComponent: CategoryField,
            inputProps: {
              blurOnMenuClose: true,
              disableUnderline: true,
              editable: true,
              allowNone: true,
              onlyL1: false,
              filterFn: (cat) => cat.type === chartOfAccountsTypes.CoaTypeEnum.CATEGORY || cat.type === chartOfAccountsTypes.CoaTypeEnum.NONE,
              menuPosition: 'absolute',
              'aria-label': 'Subcategory',
              value: item.values?.parent || '',
            },
          },
        };
        break;
      }

      default:
        return null;
    }

    return (
      <div key={sharedProps.key} style={{ flex: row.flex }} className={classes.listItemText}>
        <TextField {...sharedProps} {...fieldProps} />
      </div>
    );
  };

  const renderItemContent = () => (
    <div className={classNames(classes.itemContentContainer, narrowMode ? 'narrowMode' : '')}>
      <Dump obj={item} />
      {dataDictionary.map(
        (row, index) => {

          const showIndent = isChild;
          let root;
          if (showIndent) {
            root = classes?.[`itemIdent-L${item.level}`]; // Quicken has L2 to L16
          } else {
            root = classes.firstItem; // L1
          }

          const nextRow = (index + 1) < dataDictionary.size ? dataDictionary.get(index + 1) : undefined;

          if (row.type === 'actions' || row.type === 'actionsSelect') {
            return renderActionIcons({ row });
          }

          if (isEditing && editingProp === row.id && row.editable) {
            return renderFields({ row, index, root, nextRow });
          }

          return renderTextLabel({ row, index, primary: root });
        },
      )}
    </div>
  );

  return (
    <List id={`${baseId}-row-container`} dense className={classes.listContainer}>
      <ListItem
        id={`${baseId}-row-item`}
        className={itemClasses}
        onClick={onSelect && !disabled ? () => onSelect(item) : undefined}
      >
        {renderCollapseIcon()}
        {renderItemContent()}
      </ListItem>
    </List>
  );
};

export default ResourceRow;
