import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { usePrevious } from 'react-use';
import classNames from 'classnames';
import { noop } from 'lodash';

import { makeStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';

import RootState from 'companion-app-components/utils/redux-store/rootState';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import * as preferencesSelectors from 'companion-app-components/flux/preferences/selectors';

import type { UseStylesType } from 'components/AccountCard/types';
import AccountList from 'components/AccountList';
import { doAccountDiscovery } from 'components/Dialogs/AccountDiscovery/actions';
import { authSelectors } from 'companion-app-components/flux/auth';
import RendersCount from 'components/RendersCount';
import { AccountDrawerToggle, AccountListHeader } from './components';
import { styles } from './styles';
import { AccountDrawerState } from './types';

let userClickedOnCollapseButton: undefined | boolean = false;
const useStyles = makeStyles()(styles as Record<string, any>);

interface AccountDrawerProps {
  dimAccountList?: boolean;
  router: Record<string, any>;
  nodeSelect?: (node: any) => boolean;
  selectedNode?: string;
  drawerChange?: (open?: boolean) => void;
  open?: boolean;
  suppressColorBars?: boolean;
  accountDrawerDefaultOpen?: boolean;
  prefix?: string;
}

const AccountDrawer: React.FC<AccountDrawerProps> = (props) => {
  const dispatch = useDispatch();
  const { classes, theme }: UseStylesType = useStyles();
  const [drawerState, setDrawerState] = React.useState<AccountDrawerState>({
    drawerOpen: props.open,
    selectedAccountNodeId: props.selectedNode,
    currentRoute: null,
  });
  const datasetPreferences = useSelector((state: RootState) =>
    preferencesSelectors.getSharedPreferencesByPath(state, { group: 'dataset', path: ['webApp'] }));
  const addFiEnabled = useSelector((state: RootState) =>
    featureFlagsSelectors.getFeatureFlags(state)?.get('addFi'));
  const isWebFirstDataset = useSelector((state: RootState) =>
    authSelectors.getIsWebfirstDataset(state));
  const defaultInitiator = 'account list app';
  const dispatchDoAccountDiscovery = (initiator, directToManualAccounts = false) =>
    dispatch(doAccountDiscovery({
      initiator: initiator || defaultInitiator,
      mode: directToManualAccounts ? 'ADD-FI-MANUAL' : 'ADD-FI',
    }));

  let widthBeforeResize: null | number = null;
  const prevSelectedNode = usePrevious(props.selectedNode);
  const prevDrawerOpen = usePrevious(props.open);
  const prevAccountDrawerDefaultOpen = usePrevious(props.accountDrawerDefaultOpen);

  const handleResize = () => {
    if (props.drawerChange) {
      const isWidthChanged = window.innerWidth !== widthBeforeResize;
      widthBeforeResize = null;
      if (!isWidthChanged) {
        return;
      }
      const updateStateFlag = (userClickedOnCollapseButton && window.innerWidth <= 800) || (!userClickedOnCollapseButton);
      if (updateStateFlag) {
        setDrawerState((prevState) => ({
          ...prevState,
          drawerOpen: window.innerWidth >= theme.components.accountDrawer.collapsePoint,
        }));
      }
    }
  };

  const resizeThrottler = () => {
    let resizeTimeout: null | ReturnType<typeof setTimeout> = null;
    if (resizeTimeout) {
      clearTimeout(resizeTimeout);
    }
    resizeTimeout = setTimeout(handleResize, 66);

    if (widthBeforeResize === null) {
      widthBeforeResize = window.innerWidth;
    }
  };

  const handleDrawerToggle = (e) => {
    if (props.drawerChange) {
      e.stopPropagation();
      userClickedOnCollapseButton = drawerState.drawerOpen;
      setDrawerState((prevState) => ({
        ...prevState,
        drawerOpen: !drawerState.drawerOpen,
      }));
      props.drawerChange(!drawerState.drawerOpen);
    }
  };

  const handleAccountNodeSelect = (currentNode) => {
    let node = currentNode;
    // For Separate accounts the route can be transactions and have investment accounts inside
    if (
      node.routeTo === '/transactions' &&
      (node.account?.type === 'INVESTMENT' || node.id === 'BROKERAGE')
    ) {
      node = node.set('routeTo', '/investments');
    }
    // if the user specified a handler, call the handler.
    // if it returns 'true' also execute the default action
    let doDefault = true;
    if (props.nodeSelect) {
      doDefault = props.nodeSelect(node);
    }
    if (doDefault && node.routeTo) {
      if (typeof node === 'string') {
        setDrawerState((prevState) => ({
          ...prevState,
          selectedAccountNodeId: node,
        }));
        // if switching to a new page, do a 'push', otherwise switches on the same page do a 'replace'
      } else if (!drawerState.selectedAccountNodeId || (drawerState.currentRoute !== node.routeTo)) {
        props.router.navigate(`${node.routeTo}?displayNode=${node.id}`);
        setDrawerState((prevState) => ({
          ...prevState,
          currentRoute: node.routeTo,
        }));
      } else {
        props.router.navigate(`${node.routeTo}?displayNode=${node.id}`, { replace: true });
        setDrawerState((prevState) => ({
          ...prevState,
          currentRoute: node.routeTo,
        }));
      }
    }
  };

  const handleAddInstitutionLogin = (
    initiator: string,
    directToManualAccounts: boolean = false,
  ) => (event) => {
    if (addFiEnabled && isWebFirstDataset) {
      event?.stopPropagation();
      dispatchDoAccountDiscovery(initiator, directToManualAccounts);
    }
  };

  React.useEffect(() => {
    if (props.drawerChange) {
      window.addEventListener('resize', resizeThrottler);
    }

    return () => {
      window.removeEventListener('resize', resizeThrottler);
      userClickedOnCollapseButton = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (props.selectedNode !== prevSelectedNode) {
      setDrawerState((prevState) => ({
        ...prevState,
        selectedAccountNodeId: props.selectedNode,
      }));
    }
    if (props.open !== prevDrawerOpen) {
      setDrawerState((prevState) => ({
        ...prevState,
        drawerOpen: props.open,
      }));
    }
    // Sync the value of the preference if it is different from current state
    if (
      props.accountDrawerDefaultOpen !== prevAccountDrawerDefaultOpen &&
      props.accountDrawerDefaultOpen !== drawerState.drawerOpen
    ) {
      if (props.drawerChange) {
        props.drawerChange(drawerState.drawerOpen);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedNode, props.open, props.accountDrawerDefaultOpen, prevSelectedNode, prevDrawerOpen, prevAccountDrawerDefaultOpen]);

  const balRef = React.useRef<null | HTMLDivElement>(null);
  const { transactionRegister: regPrefs, accountBarBalanceType, accountBarShowCents } = datasetPreferences;
  const showColors = regPrefs && regPrefs.showAccountColors && !props.suppressColorBars;
  // hardcode to 'available balance', or 'today'
  const balanceType = accountBarBalanceType;

  return (
    <>
      {props.drawerChange &&
        <div
          className={drawerState.drawerOpen ? classes.overlay : classes.overlayClosed}
          onClick={handleDrawerToggle}
          role="presentation"
        />}
      <div
        ref={balRef}
        className={classes.container}
      >
        <div className={drawerState.drawerOpen ? classes.drawerPaper : classes.drawerPaperClosed}>
          <RendersCount id="accountDrawer" />
          <Box
            id={`${props.prefix}qCard-add-account`}
            className={classes.drawerHeader}
            sx={{ ...(!drawerState.drawerOpen && { bgcolor: 'inherit' }) }}
            onClick={() => props.router.navigate('/transactions?displayNode=all', { replace: true })}
            onKeyDown={noop}
          >
            <AccountListHeader
              classes={classes}
              handleAddInstitutionLogin={handleAddInstitutionLogin}
              addFiEnabled={addFiEnabled && isWebFirstDataset}
            />
            {props.drawerChange &&
              <AccountDrawerToggle
                open={drawerState.drawerOpen}
                classes={classes}
                handleDrawerToggle={handleDrawerToggle}
                anchorEl={balRef.current}
              />}
          </Box>

          {/* Accounts List */}
          <div
            className={classNames(classes.accountDrawerContent, props.dimAccountList ? 'dim' : '')}
          >
            {drawerState.drawerOpen &&
              <AccountList
                dimAccountList={props.dimAccountList}
                onSelect={handleAccountNodeSelect}
                selectedNodeId={drawerState.selectedAccountNodeId}
                handleAddInstitutionLogin={handleAddInstitutionLogin}
                prefix={props.prefix}
                showColors={showColors}
                balanceType={balanceType}
                omitCents={!accountBarShowCents}
              />}
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountDrawer;
