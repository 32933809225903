import React, { useRef, useMemo, useCallback } from 'react';
import classNames from 'classnames';

import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import CheckIcon from '@mui/icons-material/CheckRounded';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItem from '@mui/material/ListItem';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRightRounded';

interface QMenuListProps {
  key: string;
  item: Record<string, any>;
  selected?: string;
  onChange: (value: string, extraId?: string, noClose?: boolean) => void;
  classes: Record<string, string>;
  anchorHover: HTMLElement | null;
  subMouseEntered: () => void;
  name: string;
  hover: (e: React.MouseEvent<HTMLElement>, value: string) => void;
  keyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
  handleClick: (value: string, groupId?: string | null, noCloseOnClick?: boolean) => void;
  subMenuType: React.ElementType;
  theme: Record<string, any>;
}

const QMenuList: React.FC<QMenuListProps> = ({
  selected,
  theme,
  classes,
  hover,
  keyDown,
  handleClick,
  anchorHover,
  onChange,
  subMouseEntered,
  name,
  subMenuType,
  item,
}) => {

  const mySubMenu = useRef<Record<string, any>>(null);

  const dividerStyle = useCallback(
    (listItem) => listItem.divider ? {
      borderBottom: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: theme.palette.grey.level2,
      paddingBottom: theme.shape.padding[2],
      marginBottom: theme.shape.padding[2],
    } : null,
    [theme],
  );

  const makeCheckListItem = useCallback(
    (checkListItem: Record<string, any>, isSelected: boolean, groupId?: string) => {
      const id = checkListItem.value;

      return (
        <ListItem
          button
          onClick={(e) => {
            e.stopPropagation();
            handleClick(id, groupId, checkListItem.noCloseOnClick);
          }}
          key={`qmenu_li_${checkListItem.value}_${checkListItem.label}`}
          id={`qmenu_id_${checkListItem.label}`}
          value={isSelected ? 'on' : 'off'}
          style={dividerStyle(checkListItem)}
          className={classes.standardItemHoverContainer}
        >
          {isSelected && (
            <ListItemIcon className={classes.listIcon} style={{ minWidth: 0 }}>
              <CheckIcon />
            </ListItemIcon>
          )}
          {!isSelected && <div className={classes.iconFiller} />}

          <Typography variant="body2">{checkListItem.label}</Typography>
        </ListItem>
      );
    },
    [classes, handleClick, dividerStyle],
  );

  const data = useMemo(() => {
    const result: JSX.Element[] = [];

    if (item.isSubheader || item.isGroupList) {
      if (item.label) {
        result.push(
          <ListSubheader
            key={`qmenu_sub_${item.label}`}
            className={classes.parentList}
            disableSticky
          >
            <ListItemText
              className={classes.menuSubHead}
              secondary={item.label}
            />
          </ListSubheader>,
        );
      }

      if (item.isGroupList) {
        item.groupList?.forEach((x) => {
          result.push(makeCheckListItem(x, (item.selected === x.value || x.selected), item.groupId));
        });
      }
    } else if (item.customRender) {
      result.push(
        <div
          key={`qmenu_custom_${item.value}`}
          onMouseEnter={(e) => hover(e, JSON.stringify(item.value))}
        >
          <MenuItem
            style={dividerStyle(item)}
            id={`qmenu_id_${item.label}`}
            onKeyDown={(_) => keyDown}
            onClick={(e) => {
              e.stopPropagation();
              handleClick(item.value);
            }}
          >
            {item.customRender}
          </MenuItem>
        </div>,
      );
    } else if (selected) {
      result.push(makeCheckListItem(item, selected === item.value));
    } else if (item.subMenu) {
      const SubMenu = subMenuType;

      result.push(
        <ListItem
          dense
          button
          key={`qmenu_sub_${item.label}`}
          className={classes.standardItemHoverContainer}
          onMouseEnter={(e) => hover(e, JSON.stringify(item.value))}
          onKeyDown={(e) => {
            if (e.key === 'ArrowRight' && mySubMenu.current) {
              mySubMenu.current.initMenu(e);
            }
            return true;
          }}
          onClick={(e) => {
            if (item.itemOpensMenu) {
              if (mySubMenu.current) {
                mySubMenu.current.initMenu(e);
              }
              return true;
            }
            return (item.value ? handleClick(item.value, null, item.noCloseOnClick) : null);
          }}
        >
          <ListItemText
            id={`qmenu_sub_${item.label}`}
            primary={item.label}
            style={dividerStyle(item)}
            classes={{ root: classes.listTextRoot, primary: classes.listText }}
          />
          <SubMenu
            ref={mySubMenu}
            anchorEl={anchorHover}
            menuIcon={KeyboardArrowRightIcon}
            menuIconButtonSize="small-no-padding"
            menuStyle={{ lineHeight: '0px' }}
            viewBox="-3 0 27 27"
            onChange={onChange}
            options={item.subMenu}
            theme={theme}
            classes={classes}
            subMouseEntered={subMouseEntered}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            name={`${name}:sub:${item.label}`}
          >
          </SubMenu>
        </ListItem>,
      );
    } else {
      result.push(
        <ListItem
          dense
          style={dividerStyle(item)}
          className={classNames(classes.standardItem, classes.standardItemHoverContainer)}
          id={`qmenu_id_${item.label}`}
          key={`qmenu_li_${JSON.stringify(item.value)}`}
          button
          onClick={(e) => {
            e.stopPropagation();
            handleClick(item.value);
          }}
          selected={selected === item.label}
        >
          <div
            onMouseEnter={(e) => hover(e, JSON.stringify(item.value))}
            className={item.subIndent ? classes.subIndent : ''}
          >
            <ListItemText
              primary={item.label}
              classes={{ root: classes.listTextRoot, primary: classes.listText }}
            />
          </div>
        </ListItem>,
      );
    }
    return result;
  }, [item, selected, name, subMouseEntered, classes, hover, handleClick, keyDown, anchorHover, onChange, subMenuType, theme, dividerStyle, makeCheckListItem]);

  return data;
};

export default QMenuList;
