import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';

import { Collapse, List } from '@mui/material';

import ResourceRow from 'components/ResourceRegister/ResourceRow';
import { treeNodeRowStyles as styles } from 'components/ResourceRegister/styles';

import type { CategoryItem, DataDictionaryItems } from '../types';

interface TreeNodeRowProps {
  item: CategoryItem;
  rowIndex: number[];
  isChild?: boolean;
  dataDictionary: DataDictionaryItems;
  onSave: (item: CategoryItem) => void;
  onDelete: (item: CategoryItem) => void;
  onSelect: (item: CategoryItem) => void;
  selectedItems: Set<string>;
  onRemoveNewItem: (item: CategoryItem) => void;
  getCategoryByID: (id: string) => void;
  setDialog: (dialog) => void;
  editingProps: Record<string, any>;
  narrowMode?: boolean;
  disabled?: boolean;
  expandForSearch?: boolean;
}

const useStyles = makeStyles()(styles);

const TreeNodeRow: React.FC<TreeNodeRowProps> = ({
  item,
  rowIndex,
  isChild = false,
  dataDictionary,
  onSave,
  onDelete,
  onSelect,
  selectedItems,
  onRemoveNewItem,
  getCategoryByID,
  setDialog,
  editingProps,
  narrowMode = false,
  disabled = false,
  expandForSearch = false,
}) => {

  const [isExpanded, setIsExpanded] = useState(expandForSearch);

  const { classes } = useStyles();

  useEffect(() => {
    setIsExpanded(expandForSearch);
  }, [expandForSearch]);

  const handleExpandCollapse = () => {
    setIsExpanded((prev) => !prev);
  };

  const sharedProps = (data: CategoryItem, isChildren: boolean, index: number[]) => ({
    item: data,
    isChild: isChildren,
    rowIndex: index,
    narrowMode,
    dataDictionary,
    onSave,
    onDelete,
    onSelect,
    selectedItems,
    onRemoveNewItem,
    getCategoryByID,
    setDialog,
    editingProps,
  });

  return (
    <List dense key={item.id} className={classes.listContainer}>
      <ResourceRow
        isExpanded={isExpanded}
        isParent={Boolean(item.children)}
        onCollapse={handleExpandCollapse}
        {...sharedProps(item, isChild, rowIndex)}
        disabled={disabled}
      />
      {isExpanded && (
        <Collapse
          in={isExpanded}
          className={classNames(classes.collapseContainer, narrowMode ? 'narrowMode' : '')}
        >
          {item.children?.map((child: CategoryItem, childIndex: number) => (
            <TreeNodeRow
              key={child.id}
              {...sharedProps(child, true, [...rowIndex, childIndex + 1])}
              disabled={selectedItems?.has(item.id) || disabled}
            />
          ))}
        </Collapse>
      )}
    </List>
  );
};

export default TreeNodeRow;
