import React, { useState } from 'react';
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import SearchBox from 'components/SearchBox';
import TreeNodeRow from 'components/ResourceRegister/TreeNodeRow';

import type { CategoryItem, DataDictionaryItems } from './types';
import { resourceRegisterStyles as styles } from './styles';

const useStyles = makeStyles()(styles as Record<string, any>);

interface ResourceRegisterProps {
  data: CategoryItem[];
  narrowMode?: boolean;
  dataDictionary: DataDictionaryItems;
  onSave: (item: any) => void;
  onDelete: (id: string | number) => void;
  onSelect: (id: string | number) => void;
  selectedItems: Record<string, boolean>;
  onRemoveNewItem: (id: string | number) => void;
  getCategoryByID: (id: string | number) => any;
  setDialog: (dialogProps: any) => void;
  noHeader?: boolean;
  autoFocus?: boolean;
  onSearchChange?: (searchTerm: string) => void;
  resourceType?: string;
  children?: React.ReactNode;
  placeholder?: string;
  noBorder?: boolean;
  searchChildren?: boolean;
}

const ResourceRegister: React.FC<ResourceRegisterProps> = ({
  data,
  dataDictionary,
  onSave,
  onDelete,
  onRemoveNewItem,
  getCategoryByID,
  narrowMode = false,
  setDialog,
  onSelect,
  selectedItems,
  onSearchChange,
  resourceType,
  autoFocus = false,
  children,
  placeholder,
  noBorder = false,
  searchChildren = false,
  noHeader = false,
}) => {

  const { classes } = useStyles();

  const [editingId, setEditingId] = useState<string | number | null>(null);
  const [cancelEditingId, setCancelEditingId] = useState<string | number | null>(null);

  const requestEditing = (id: string | number) => {
    setCancelEditingId(editingId !== id ? editingId : cancelEditingId);
    setEditingId(id);
  };

  const cleanEditingData = (id?: string | number) => {
    setEditingId(id || null);
    setCancelEditingId(null);
  };

  const renderHeader = () => (
    <div className={classes.headerContainer}>
      {dataDictionary.map((row, index) => {
        const labelStyles = row.type === 'actions' ? { minWidth: 140 } : {};
        return (
          <Typography
            key={row.id}
            style={{ ...labelStyles, flex: row.flex }}
            variant="subtitle2"
            className={classNames(classes.baseRowHead, {
              [classes.firstRowHead]: index === 0,
            })}
          >
            {row.label}
          </Typography>
        );
      })}
    </div>
  );

  const renderRows = () => (
    <div className={classes.rowsContainer}>
      {children}
      {onSearchChange && (
        <SearchBox
          classes={{ smartSearch: noBorder ? classes.smartSearchNoBorder : classes.smartSearch }}
          onSearch={onSearchChange}
          autoFocus={autoFocus}
          placeholder={placeholder || `Search ${resourceType || 'items'}`}
          autoSearch
        />
      )}
      {noBorder && <Divider />}
      {data.map((item, index) => (
        <TreeNodeRow
          key={item.id}
          narrowMode={narrowMode}
          item={item}
          rowIndex={[index]}
          dataDictionary={dataDictionary}
          onSave={onSave}
          onDelete={onDelete}
          onRemoveNewItem={onRemoveNewItem}
          getCategoryByID={getCategoryByID}
          setDialog={setDialog}
          onSelect={onSelect}
          selectedItems={selectedItems}
          expandForSearch={searchChildren}
          editingProps={{
            editingId,
            cancelEditingId,
            requestEditing,
            cleanEditingData,
          }}
        />
      ))}
    </div>
  );

  return (
    <div className={classes.rootContainer}>
      {!noHeader && renderHeader()}
      {renderRows()}
    </div>
  );
};

export default ResourceRegister;
